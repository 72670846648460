.root {
  --tooltip-triangle-size: 8;
  --track-height-px: 3px;
  --knob-size-px: 12px;
}

.track:hover {
  --track-height-px: 4px;
  --knob-size-px: 16px;
}

.track,
.track > * {
  cursor: pointer;
  touch-action: pan-y;
}

.track_base {
  height: var(--track-height-px);
}

.track_loaded {
  width: calc(var(--progress-loaded) * 100%);
  height: var(--track-height-px);
}

.track_played {
  width: calc(var(--progress-played) * 100%);
  height: var(--track-height-px);
}

.knob {
  left: calc(var(--progress-played) * 100%);
  width: var(--knob-size-px);
  height: var(--knob-size-px);
}

.knob__sliding {
  left: calc(var(--slider-value) * 100%);
}

.tooltip {
  /* TODO: style tooltip for touch devices */
  @apply hidden lg:block;
  left: clamp(
    calc(var(--storyboard-width) / 2 * 1px - var(--tooltip-triangle-size) * 1px),
    calc(var(--hover-x) * 1px),
    calc(var(--hover-width) * 1px - var(--storyboard-width) / 2 * 1px + var(--tooltip-triangle-size) * 1px)
  );
  width: calc(var(--storyboard-width) * 1px);
  height: calc(var(--storyboard-height) * 1px);
  transform: translate(calc(var(--storyboard-width) / 2 * -1px), calc(var(--storyboard-height) * -1px));
}

.tooltip_img {
  transform: translate(calc(var(--storyboard-position-x) * -1px), calc(var(--storyboard-position-y) * -1px));
}

.tooltip_triangle {
  /* TODO: style tooltip for touch devices */
  @apply hidden lg:block;
  left: clamp(
    calc(var(--hover-x) * 1px - var(--tooltip-triangle-size) * 1px - 8px),
    calc(var(--hover-x) * 1px),
    calc(var(--hover-x) * 1px + var(--tooltip-triangle-size) * 1px - 8px)
  );
  width: calc(var(--tooltip-triangle-size) * 1px);
  height: calc(var(--tooltip-triangle-size) * 1px);
  border: calc(var(--tooltip-triangle-size) * 1px) solid transparent;
}
