@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'BodoniSevITC W08 Regular';
  src:
    url('/fonts/1557468/5d60805f-567f-4ccb-920d-fefe77577fd5.woff2') format('woff2'),
    url('/fonts/1557468/c2dcad27-a62d-40b0-88a4-fd4b1126d0cc.woff') format('woff');
}
@font-face {
  font-family: 'BodoniSevITC Italic W08 Rg';
  src:
    url('/fonts/1557470/e8f76d64-52d0-45a3-9616-2403acec1030.woff2') format('woff2'),
    url('/fonts/1557470/3cb77b99-7b69-430d-ba73-a48c6a88c6ad.woff') format('woff');
}
@font-face {
  font-family: 'BodoniSevITC Bold W08 Regular';
  src:
    url('/fonts/1557474/7ebfd050-163a-472d-b8c1-d353c1e03b2e.woff2') format('woff2'),
    url('/fonts/1557474/4a3db9c0-1b99-4c42-91b0-d913a63fe676.woff') format('woff');
}
@font-face {
  font-family: 'BodoniSevITC Bold Italic W08';
  src:
    url('/fonts/1557476/01b2c10b-271c-462c-8347-52530769bcbd.woff2') format('woff2'),
    url('/fonts/1557476/0957e7b9-070e-4ae4-b146-e598ffb82fc3.woff') format('woff');
}

body {
  @apply bg-khaki-2 text-slate font-lato overscroll-y-none;

  min-width: 320px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.75;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'pnum';
  font-variant-numeric: proportional-nums;
  -webkit-text-size-adjust: 100%;
}

body,
#__next {
  @apply min-h-screen;
}

#__next {
  /* overflow-x-hidden on #__next is necessary since our carousels
     overflow the viewport width and are set to overflow-visible */
  /* @apply flex flex-col overflow-x-hidden; */
  @apply flex flex-col;
}

button {
  border: inherit;
  background: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  appearance: none;
}

video {
  /* min-width: 100%; */
  @apply max-w-full;
}

table {
  @apply box-content w-full border-collapse bg-khaki-1 rounded-lg overflow-hidden;
}

thead {
  @apply border-b border-b-khaki-3 bg-khaki-1;
}

tbody {
  @apply text-sm;
}

thead tr {
  @apply border-b border-khaki-3;
}

th:first-of-type {
  @apply pl-14;
}

th:last-of-type {
  @apply pr-14;
}

thead th {
  @apply py-6  px-3 font-josefin text-xs tracking-tighter text-left whitespace-nowrap;
}

tbody tr td {
  @apply py-3 px-3 text-sm;
}

tbody tr:first-of-type td {
  @apply pt-5;
}

tbody tr:last-of-type td {
  @apply pb-6;
}

tbody tr td:first-of-type {
  @apply pl-14;
}

tbody tr td:last-of-type {
  @apply pr-14;
}

tfoot {
  @apply text-center bg-khaki-2-3;
}

tfoot tr td {
  @apply py-6;
}

form {
  @apply flex flex-col gap-5 lg:gap-y-8;
}

/* Form */
input[type='search']::-webkit-search-cancel-button {
  @apply hidden;
}

/* Toast */
.toast {
  @apply !bg-khaki-1 !border !border-khaki-5 !px-4 !py-3 !text-base !text-slate !z-50;
}
